<template>
	<div id="theme" class="el-content">
        <a-tabs defaultActiveKey>
            <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.tab">
                <!-- 农场设置 -->
                <div v-if="item.key=='basic'">
                    <com-power-form :form-data="[
                        {label:'农场名称',type:'text',value:'',key:'farm_name',dataType:'string'},
                        {label:'农场LOGO',type:'image',value:'',key:'farm_logo',dataType:'string'},
                        {label:'联系电话',type:'text',value:'',key:'farm_phone',dataType:'string'},
                        {label:'首页分享标题',type:'text',value:'',key:'farm_home_share_title',dataType:'string'},
                        {label:'新用户判断天数',type:'number',value:'',key:'farm_new_user_day',dataType:'string',addon:'天'},
                        {label:'首页弹窗弹出规则',type:'select',value:'',key:'pop_up_rules',dataType:'string',
                            options:[
                                    {label:'每天首次进入小程序弹出一次',value:'1'},
                                    {label:'仅弹出一次',value:'2'},
                            ],
                            tips:'小提示：首页弹窗请到自定义页面中选择“弹出层”组件设置！'
                        },
                    ]"></com-power-form>
                </div>
                <div v-if="item.key=='weather'">
                    <com-power-form :form-data="[
                        {label:'腾讯地图key',type:'text',value:'',key:'tencent_map_key',dataType:'string',
                            tips:'没有腾讯地图Key？<a href=https://lbs.qq.com/ target=_blank>立即获取</a>',
                        },
                        {
                            label:'农场地址经纬度',type:'map',value:'',
                            key:['weather_longitude','weather_latitude'],
                            dataType:'string',
                            tips:'农场地址经纬度影响天气准确性展示',
                        },
                        {label:'天气接口Token',type:'text',value:'',key:'weather_token',dataType:'string',
                            tips:'没有天气接口Token？<a href=https://dashboard.caiyunapp.com/user/sign_in/ target=_blank>立即获取</a>'
                        },
                    ]"></com-power-form>
                </div>
                <div v-if="item.key=='score'">
                    <com-power-form :form-data="[
                        {label:'签到一次获得积分',type:'number',value:'',key:'socre_get_sign',dataType:'string'},
                        {label:'积分自定义名称',type:'text',value:'',key:'score_name',dataType:'string',
                            tips:'积分自定义名称将客户端所有的 “ 积分 ” 关键词设置成自定义名称 ，字数不超过三个字。'
                        },
                        {label:'积分规则',type:'editor',value:'',key:'score_rule',dataType:'string'},
                    ]"></com-power-form>
                </div>
                <div v-if="item.key=='pay'">
                    <com-power-form :form-data="[
                        {
                            label:'支付方式',
                            type:'checkbox',
                            value:'',
                            key:'shop_pay_method',
                            dataType:'json',
                            options:[
                                {label:'微信支付',value:'wxpay'},
                                {label:'支付宝支付',value:'alipay'},
                                {label:'余额支付',value:'balance_pay'},
                            ],
                            tips:'支付方式如果一个都不选择,则默认全部支付方式都支持'
                        },
                        { label:'是否开启提现', type:'switch', value:'', key:'recharge_is_open_withdraw', dataType:'string', },
                        { label:'提现手续费设置',
                            type:'number',
                            value:'',
                            key:'recharge_service_charge',
                            dataType:'string',
                            addon:'‰',
                            tips:'注意：不设置提现手续费，则表示提现不会扣除手续费。请注意手续费是 “千分之X” '
                        },
                        { label:'最低提现金额', type:'number', value:'', key:'recharge_low_withdraw', dataType:'string', addon:'元', },
                        { label:'返现金额可提现比例',
                            type:'number',
                            value:'',
                            key:'cashback_service_withdraw',
                            dataType:'string',
                            addon:'‰',
                            tips:'注意：返现金额可提现比例，则表示提现不会扣除手续费。请注意手续费是 “千分之X” '
                        },
                    ]"></com-power-form>
                </div>
                <div v-if="item.key=='recharge'">
                    <com-power-form :form-data="[
                        {label:'是否开启充值',type:'switch',value:'',key:'recharge_is_open_recharge',dataType:'string'},
                        {label:'是否开启充值赠送',type:'switch',value:'',key:'recharge_is_open_send',dataType:'string'},
                        {label:'充值赠送规则',type:'recharge-rule',value:'',key:'recharge_give',dataType:'json'},
                        {label:'充值协议',type:'editor',value:'',key:'recharge_agreement',dataType:'string'},
                    ]"></com-power-form>
                </div>
                <div v-if="item.key=='farm-desc'">
                    <com-power-form :form-data="[
                        {label:'农场简介',type:'editor',value:'',key:'farm_desc',dataType:'string'},
                    ]"></com-power-form>
                </div>
            </a-tab-pane>
        </a-tabs>
	</div>
</template>
<script>
import { reactive, ref, toRefs } from 'vue'
import rh from '@/router/routerHandle.js'
import comPowerForm from '@/components/form/com-power-form.vue'
export default{
    components:{
        comPowerForm
    },
    setup(){
        const active = ref("basic")
        const _d = reactive({
            tabs:[],
        })
        _d.tabs = rh.getCuurentTabs("basic-set")

        return {
            ...toRefs(_d),
            active,
        }
    }
}
</script>

<style lang="scss" scoped>
	.ri-top{
        cursor: pointer;
    }
</style>
